import React, { useEffect, useState , useCallback} from 'react';
import { ToastContainer } from 'react-toastify';
import {ScrollToTop} from '../layout';
import { setColor } from '../config';
import {performLogin, performLogout } from '../store/auth/authSlice';
import { SSO_ORIGIN } from '../store/CONSTANTS';
import { useDispatch } from 'react-redux';
import Dialog from '../layout/UI/dialog/dialog';
import { establishCommunicationToSSO , debounce} from '../utils/helper';
import { useNavigate } from 'react-router-dom';

const Layout = (props: any) =>{
    const dispatch: (...args: unknown[]) => Promise<any> = useDispatch<any>();
    const [render, setRender] = useState(false);
    const events = [
        "mouseup", 
        "keydown", 
        "scroll", 
        "mousemove"
    ];
    const [confirmPopup, setConfirmPopup] = useState({
        isVisible: false,
        data: 0
      })
      const [seconds, setSeconds] = useState(120);
      const navigate = useNavigate();

    // const dispatch = useDispatch();
    useEffect(()=>{
        setColor();
        window.addEventListener("message", (event) => {
            if(event.origin !== SSO_ORIGIN){
                return;
            }
            actionHandler(event.data)
        })        
        // window.addEventListener('DOMContentLoaded', function(){
            events.forEach(function (e) {
              document.getElementById('hubble').addEventListener(e, function(){
                userActivityTracker();
              })
            })
          // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
      let timer: any ;
      if (confirmPopup.isVisible) {
        let time = seconds;
        timer = setInterval(() => {
          if(time <= 1 ) {
            window.location.reload();
            clearInterval(timer);
          }
          time = time - 1;
          setSeconds(time);
        }, 1000);
      }else{
        clearInterval(timer);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [confirmPopup.isVisible]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const userActivityTracker: any = useCallback(debounce(()=>{
       establishCommunicationToSSO({
          event: "updateActivityTime",
        });
      }, 3000), []);

    const actionHandler = (data: any) =>  {
        if(data.event === 'jwtinfo'){
        if(data && data.record){
            localStorage.setItem('token', JSON.stringify(data.token))
            dispatch(performLogin(data));
        }
        else {dispatch(performLogout());
        navigate('/')
        }
        setRender(!render);
        setConfirmPopup({
          ...confirmPopup,
          isVisible: false,
        });
       }
       if(data.event === 'expiry_notification'){
        const toTime = new Date(data.expiry_time);
        const difference = toTime.getTime() - new Date().getTime();
        const timeout = Math.floor(difference/1000);
        setSeconds(timeout);
        setRender(!render);
        // console.log(data, difference, Math.floor(difference));
            setConfirmPopup({
              ...confirmPopup,
              isVisible : true,
            })
       }
}

    
    return(
        <>
        <iframe id="ssoFrame" style= {{"display":"none"}} src={SSO_ORIGIN} title="SSO"></iframe>
         <ScrollToTop />
         <div id = "hubble">
         {props.children}
         </div>
         <ToastContainer />
         <Dialog  
            static
            show={confirmPopup.isVisible}
            crossMark={true}
            handleClose = {() => {
                dispatch(performLogout());
                setConfirmPopup({
                ...confirmPopup, isVisible : !confirmPopup.isVisible
            })}}
                title = "Are you still there?" buttonText = {["Log Out", "Stay Logged In"]}
                saveChanges = {() =>{
                    establishCommunicationToSSO({
                        "event" : "refreshToken"
                    })
                    setConfirmPopup({
                        ...confirmPopup, isVisible : !confirmPopup.isVisible
                    })
                }}
                >
                Your session will expire in {seconds} seconds. Do you wish to remain logged in?
            </Dialog>
        </>
    )
}

export default Layout;

