/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState , useCallback} from 'react';
import {  Dialog, ButtonCustom, showToast } from '../../../../../layout';
import Grid from '../../../../../layout/UI/dataGrid';
import { getProjectFilesList,uploadFileMulti, updateProjectFiles, deleteFilesList, updateGdriveLink,Approvelist, getDownloadFileUrls, setSelectionModel, setFileSelectionModel, setFileData } from '../../../../../store/projectList/projectListSlice';
import { setActiveModuleTitle } from '../../../../../store/config/configSlice';
import { CDN_URL, LANG } from '../../../../../config';
import { capitalize, isActionAllowed } from '../../../../../utils/helper';
import moment from 'moment';
import 'moment-timezone';
import { DASHBOARD } from '../../../../../navigation/CONSTANTS';
import { useNavigate , useParams} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FileListFilter from './fileListFilter';
import Dropzone from '../../../../../layout/UI/dropZone/dropZone';
import { Checkbox, MenuItem, Select } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid/components/cell';
import ToggleSwitch from '../../../../../layout/UI/toggle';
import '../ProjectList/ProjectList.style.scss'

import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from "lodash";
import { debounce } from '../../../../../utils/helper';

import JSZip from "jszip";


const ProjectFilesList = () => {
    const projectFilesData = useSelector((store:any) => store.projectList)
    const history = useNavigate();
    const dispatch = useDispatch();
    const {id}  = useParams();
    const [inputText, setInputText] = useState('');
    const [rowIndex, setrowIndex] = useState(0);
    const [designPreviewCounter, setdesignPreviewCounter] = useState(null);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [downloadableFiles, setDownloadableFiles] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [images, setImages] = useState([]);
    const [downloadFlag, setDownloadFlag] = useState(false);
    const [name, setName] = useState('');
    const [fileDataDrop, setFileDataDrop] = useState<File[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [enableApprove, setenableApprove] = useState(true);
    const [confirmPopup, setConfirmPopup] = useState({
        isVisible: false,
        title: '',
        subTitle: '',
        buttonText: ['Cancel', 'Save'],
        data: '',
        label: '',
        id: ''
    })
    const [actionType, setActionType] = useState({
        value: '', errorMsg: ''
    })
    //table
    const productNamingMapping = {
        "utility": "Utility Bill",
        "electricitybill": "Utility Bill",
        "panel_box_images": "Panel Box Photo",
        "panelboxphoto": "Panel Box Photo",
        "panel_box_photo": "Panel Box Photo",
        "ev_charger_location": "EV Charger Photos",
        "wh_image": "Water Heater Image",
        "design": "Design File",
        "designpreview": "Design Preview",
        "designerpanelgroup": "Design File",
        "revision": "Design File",
        "feedback": "Design File",
        "quote": "Competitor Quote",
        "site evaluations": "Site Evaluation",
        "siteevaluation": "Site Evaluation",
        "other": "Other",
        "electrum_drive": "Other",
        "org_drive": "Other",
        "installer_drive": "Other",
        "mockup2d": "Site Images",
        "mockup3d": "Site Images",
        "irradiance": "Site Images",
        "streetview": "Site Images",
        "angle1": "Site Images",
        "angle2": "Site Images",
        "contract": "Contract",
        "contract cocuments": "Contract",
        "contractdocuments": "Contract",
        "usagedata": "Usage Data",
        'shading_reort': 'Shading Report',
        'SGIP' : 'SGIP',
        'contract_addendum' : 'Contract Addendum',
        "installation_photos":"Installation Photos",
        "incentives_documents": "Incentives Documents",
    }
    const [headerText, setHeaderText] = useState('');

    useEffect(() =>{
        let designFlag =false;
        let designprevieFlag =false;
        let designPreviewCounter : any = 0;
        if(projectFilesData.fileSelectionModel.length >=2){
            projectFilesData.fileSelectionModel.forEach((item)=> {
             if(item.type === 'design'){
                designFlag = true;
             }
             if(item.type === 'designpreview'){
                designprevieFlag = true;
                designPreviewCounter = designPreviewCounter+1;
             }
            })
        }else{
            setenableApprove(true);
        }
        if(designprevieFlag && designFlag){
            setenableApprove(false);
        }else{
            setenableApprove(true);
        }
        if(designPreviewCounter>1){
            setdesignPreviewCounter(designPreviewCounter)

        }else{
            setdesignPreviewCounter(designPreviewCounter)
            designPreviewCounter =0;

        }
       }, [projectFilesData.fileSelectionModel] )
  

    useEffect(() => {
        let searchData : any = {...projectFilesData.search,"string":inputText}
        getProjectFilesData({
            'projectId': id,
            'limit':pageSize,
            'page':pageNumber,
            'search':searchData
        });
        getDownloadFileUrls(id,{ids:[]}).then((response:any)=>{
            if(response.data.success){
                setDownloadableFiles(response.data.data);
            }
        })
        updateGdriveLink({ 'projectId': id})
            .then((files) => {
                if (!files.data.data.status) {
                    showToast('INFO', files.data.data.message)
                }
        })
        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProjectFilesData : any = (payload:any) => {
        getProjectFilesList(payload)
            .then((files) => {
                if (files.data.success) {
                    dispatch(setFileData(files.data.data.project_files));
                    let name: any = capitalize(files.data.data?.firstname) + ' | ' + files.data.data?.address;
                    setHeaderText(capitalize(files.data.data?.firstname) + ' | ' + files.data.data?.address);
                    dispatch(setActiveModuleTitle(name))
                    setName(`${files.data.data?.firstname+'_'+files.data.data?.lastname+'_'+id+'_files.zip'}`);
                    setTotalCount(files.data.data.total_count);
                }
            }).catch((error: any) => {
                history(DASHBOARD.PROJECT_LIST);
             })
    }

    const onDrop = useCallback((acceptedFiles:any) => {
        setFileDataDrop(acceptedFiles);
        acceptedFiles.map((file: any) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            setImages((prevState) => [
              ...prevState,
              { id: 1, src: e.target.result },
            ]);
          };
          reader.readAsDataURL(file);
          return file;
        });
      }, []);

    const searchHandler = () => {
        setPageNumber(1);
        let searchData : any = {...projectFilesData.search,"string":inputText}
        getProjectFilesData({
            'projectId': id,
            'limit':pageSize,
            'page':1,
            'search':searchData
        });
    }


    const getDate = (date: any, type: any) => {
        if (date === null) return;
        const newDate = new Date(date);

        return type === 'date' ? moment.utc(newDate).tz('America/Los_Angeles').format('MM/DD/yyyy hh:mm a') : moment.utc(newDate, 'hh:mm A').tz('America/Los_Angeles').format('MM/DD/yyyy - hh:mm A');
    }
  
    const getActions = (params: any,index:any) => {
        let actions: any = [];
        if (isActionAllowed(['can-download-project-files'])) {
            actions.push(<GridActionsCellItem
                icon={<>
                    <ButtonCustom label="Download" variant="text"
                        buttonColor="info"
                        styleClass="file-list-action" startIcon="icon-download"
                    />
                    <a href={params.row.metadata.url} target="_blank" download={params.row.metadata.original_name} id={`download_${params.row.id}`} className="d-none" rel="noreferrer"> file</a>
                </>
                }
                label = ''
                onClick={() => downloadFileHandler(params.row.metadata)}
            />);
        }
        actions.push(<GridActionsCellItem
            icon={<ButtonCustom label="View" variant="text"
                buttonColor="info"
                styleClass="file-list-action" startIcon="icon-eye" />}
                label=''
                onClick={() => {
                previewImageHandler(params.row);
            }}
        />)

        if((params.row.metadata.type==='design' || params.row.metadata.type ==='designpreview') && ( (params.row.metadata.url.includes('pdf'))||(params.row.metadata.url.includes('jpg'))||(params.row.metadata.url.includes('png') ||(params.row.metadata.url.includes('csv'))))){
            actions.push( 
                <span>
                <Checkbox
                checked= {(params.row.status || (projectFilesData.fileSelectionModel.find((file:any)=>file.id === params.row.id)))?true:false}
                onClick={(e) => {
                    handleFileId(e,params.row,index);
                }}
                />
             Approve
                </span>)

        }
     
        return actions;
    }

    const handleFileId =(e,row, index) =>{
        let tableData : any = JSON.parse(JSON.stringify(projectFilesData.fileData));
    
        tableData[index]['status'] = e.target.checked;
        
         if(e.target.checked){
            let fileSelection : any = [...projectFilesData.fileSelectionModel,{id:row.id,type:row.type}]
        dispatch(setFileSelectionModel(fileSelection));
        }else{
            let updatedFileSelectionModelArray : any = projectFilesData.fileSelectionModel.filter((item)=> item.id !== row.id)
            dispatch(setFileSelectionModel(updatedFileSelectionModelArray));
        }
      dispatch(setFileData(tableData))
    }

    const downloadFileHandler = (data: any) =>{
        if((data.url).includes('.pdf')){
        downloaderAttribute(data.url,data.original_name,'_blank')
        }else{
            downloaderAttribute(data.url,data.original_name,'_self')
        }
    }

    const downloaderAttribute = (downloadLink: any,name: any,target: any) =>{
        const aElement = document.createElement(`a`);
            aElement.setAttribute('download', name);
            aElement.href = downloadLink;
            aElement.setAttribute('target', target);
            document.body.appendChild(aElement)
            aElement.click();
            URL.revokeObjectURL(downloadLink);
    }

    const previewImageHandler = (row: any) => {
        let getIndexOfFile = 0;
        projectFilesData.fileData.forEach((item, index) => {
            if (item.id === row.id)
                getIndexOfFile = index;
        })
        setConfirmPopup({
            ...confirmPopup,
            isVisible: !confirmPopup.isVisible,
            buttonText: [],
            title: row.metadata.original_name,
            label: 'view',
            id: row
        })
        setrowIndex(getIndexOfFile);

    }

    const accessHandler = (row: any) => {
        const data = {
            fileId: row.id,
            shared_external: row.shared_external === 0 ? 1 : 0,
            type: "shared_external"
        };
        updateProjectFiles(data).then((response) => {
            if (response.data.success) {
                let searchData : any = {...projectFilesData.search,"string":inputText}
                getProjectFilesData({
                    'projectId': id,
                    'limit':pageSize,
                    'page':pageNumber,
                    'search':searchData
                });
                showToast('SUCCESS', 'Successfully file access updated!')
            } else {
                showToast('ERROR', LANG.API_ERROR)

            }
        })
    }

    const fileSelectionHandler = (rowData:any) => {
        const fileIds :any = [...projectFilesData.selectionModel];
        const index = fileIds.indexOf(rowData.id);
        if (index !== -1) {
            fileIds.splice(index, 1); // Remove file ID from the array
        } else {
            fileIds.push(rowData.id); // Add new filed ID to the array
        }
        dispatch(setSelectionModel(fileIds));
    }

    const getAction : any = (params: any) => {
        const actions = [];
        actions.push(
          <Checkbox
            id='check'
            checked={projectFilesData.selectionModel?.includes(params.row.id)}
            onClick = {() => fileSelectionHandler(params.row)}
          />
        );
        return actions;
      }

    const columns = React.useMemo(() => 
    isActionAllowed(["can-delete-project-files"]) ? [
        {
            field: "Selection",
            type: "actions",
            minWidth: 60,
            flex:1,
            renderHeader: (params:any) => (
                <div>
                    <Checkbox
                        id='check'
                        style={{marginLeft:'7px'}}
                        checked={selectAll}
                        onClick = {(e:any) => {
                            const fileIds :any = [...projectFilesData.selectionModel];
                            setSelectAll(e.target.checked);
                            if(e.target.checked){
                                downloadableFiles?.forEach((eachFile:any)=>{
                                if(!fileIds.includes(eachFile.id)){
                                    fileIds.push(eachFile.id)
                                }
                            })
                            dispatch(setSelectionModel(fileIds));
                            }else{
                                const emptyArray : any = [];
                                dispatch(setSelectionModel(emptyArray));
                            }
                        }}
                    />
                </div>
            ),
            headerName: "Selection",
            cellClassName: "left-align",
            headerAlign: "left",
            renderCell: (params: any) => getAction(params),
          },
        {
            field: "original_name", type: "string", headerName: "File Name", minWidth: 250, flex:1, cellClassName: 'left-align',
            valueGetter: ({ row }) => {
                return capitalize(row.metadata?.original_name);
            }
        },
        {
            field: "type", type: "string", headerName: "File Type", minWidth: 170, flex:1, cellClassName: 'left-align',
            valueGetter: ({ row }) => {
                return productNamingMapping[row.metadata.type.toLowerCase()] ? productNamingMapping[row.metadata.type.toLowerCase()] : row.metadata.type;
            }
        },
        {
            field: "status", type: "string", headerName: "Status", minWidth: 150, flex:1, cellClassName: 'left-align',
            valueGetter: ({ row }) => {
                return (row.status === 0 ? 'Rejected' : row.status === 1 ? 'Approved' : '-');
            }
        },
        {
            field: "created_at", type: "string", headerName: "Date Uploaded", minWidth: 200, flex:1, cellClassName: 'center-align',
            valueGetter: ({ row }) => {
                return (row.created_at ? getDate(row.created_at, '') : 'NA');
            }
        },
        {
            field: "deleted_by", type: "string", headerName: "Uploader", flex: 1, minWidth: 230, cellClassName: 'center-align',
            valueGetter: ({ row }) => {
                return (row.uploaded_by ? row.uploaded_by : 'NA')
            }
        },
        {
            field: "shared_external",
            type: "actions",
            headerName: "Actions",
            flex: 1, minWidth: 350, cellClassName: 'button-control',
            renderCell: (params) => getActions(params,params.api.getRowIndex(params.row.id)),

        },
        {
            field: "id",
            type: "actions",
            headerName: "Access",
            minWidth: 120, flex: 1,
            hide: !isActionAllowed(['can-update-project-files']),
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={
                        <ToggleSwitch
                            SwitchType='controlled'
                            checked={params.row.shared_external === 1}
                            variant='electrum'
                            handleChange={() => accessHandler(params.row)}
                        />
                    }
                    label= ''
                />
            ]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]:[
        {
            field: "original_name", type: "string", headerName: "File Name", minWidth: 250, flex:1, cellClassName: 'left-align',
            valueGetter: ({ row }) => {
                return capitalize(row.metadata?.original_name);
            }
        },
        {
            field: "type", type: "string", headerName: "File Type", minWidth: 170, flex:1, cellClassName: 'left-align',
            valueGetter: ({ row }) => {
                return productNamingMapping[row.metadata.type.toLowerCase()] ? productNamingMapping[row.metadata.type.toLowerCase()] : row.metadata.type;
            }
        },
        {
            field: "status", type: "string", headerName: "Status", minWidth: 150, flex:1, cellClassName: 'left-align',
            valueGetter: ({ row }) => {
                return (row.status === 0 ? 'Rejected' : row.status === 1 ? 'Approved' : '-');
            }
        },
        {
            field: "created_at", type: "string", headerName: "Date Uploaded", minWidth: 200, flex:1, cellClassName: 'center-align',
            valueGetter: ({ row }) => {
                return (row.created_at ? getDate(row.created_at, '') : 'NA');
            }
        },
        {
            field: "deleted_by", type: "string", headerName: "Uploader", flex: 1, minWidth: 230, cellClassName: 'center-align',
            valueGetter: ({ row }) => {
                return (row.uploaded_by ? row.uploaded_by : 'NA')
            }
        },
        {
            field: "shared_external",
            type: "actions",
            headerName: "Actions",
            flex: 1, minWidth: 350, cellClassName: 'button-control',
            renderCell: (params) => getActions(params,params.api.getRowIndex(params.row.id)),

        },
        {
            field: "id",
            type: "actions",
            headerName: "Access",
            minWidth: 120, flex: 1,
            hide: !isActionAllowed(['can-update-project-files']),
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={
                        <ToggleSwitch
                            SwitchType='controlled'
                            checked={params.row.shared_external === 1}
                            variant='electrum'
                            handleChange={() => accessHandler(params.row)}
                        />
                    }
                    label= ''
                />
            ]
        }
        // eslint-disable-next-line
    ], [projectFilesData.fileData,pageNumber,pageSize,projectFilesData.selectionModel,projectFilesData.fileSelectionModel])


    const saveButtonHandler = () => {
        switch (confirmPopup.label) {
            case 'upload': uploadFileHandler(); break;
            case 'delete': deleteHandler(); break;
            case 'Filesapprove': approveFilesHandler(); break;
            default: console.log('executing default condition')
        }
    }
    const uploadFileHandler = () => {
        const formdata = new FormData();
        if (actionType.value === '' || fileDataDrop.length <= 0) {
            setActionType({ ...actionType,  errorMsg: 'Please select the file and its type' });
        } else {
            const imageData = new FormData();
            let totalFiles = 0;
            for (var i = 0; i < fileDataDrop.length; i++) {
                imageData.append(`myfile[${totalFiles}]`, fileDataDrop[i])
                totalFiles = totalFiles + 1;
              }
              imageData.append('project_id', id);
              imageData.append('type', actionType.value);
            if(fileDataDrop.length > 0) {  
            uploadFileMulti(imageData)
                .then((res) => {
                    if (res.data.success) {
                        let searchData : any = {...projectFilesData.search,"string":inputText}
                        getProjectFilesData({
                            'projectId': id,
                            'limit':pageSize,
                            'page':pageNumber,
                            'search':searchData
                        });

                        getDownloadFileUrls(id,{ids:[]}).then((response:any)=>{
                            if(response.data.success){
                                setDownloadableFiles(response.data.data);
                            }
                        })
                        showToast('SUCCESS', `${totalFiles} ${totalFiles > 1 ? 'files' : 'file'} uploaded successfully.`)
                        setConfirmPopup({ ...confirmPopup, isVisible: !confirmPopup.isVisible });
                    } 

                }).catch((error: any) => showToast('ERROR', error?.response?.data?.message));
            setActionType({ ...actionType, errorMsg: '' });
            }
        }
    }

    const deleteHandler = () => {
        const data = {
            file_ids: projectFilesData.selectionModel
        }
        deleteFilesList(data).then((response) => {
            if (response.data.success) {
                let searchData : any = {...projectFilesData.search,"string":inputText}
                getProjectFilesData({
                    'projectId': id,
                    'limit':pageSize,
                    'page':pageNumber,
                    'search':searchData
                });
                showToast('SUCCESS', 'Successfully file deleted!');
            }
        })
        setConfirmPopup({ ...confirmPopup, isVisible: !confirmPopup.isVisible });
    }

    // downloadAll() function will zip all the files in the files list and download the Zip file
    const downloadAll = async () => {
        // Flag true
        let errCount : any = 0;
        setDownloadFlag(true);
        let zip = new JSZip();
        let selectedFiles : any = [...downloadableFiles];
        if(projectFilesData.selectionModel.length > 0){
            selectedFiles  = downloadableFiles.filter((item:any)=>projectFilesData.selectionModel.includes(item.id))
        }
        for (let index = 0; index < selectedFiles.length; index++) {
            const item = selectedFiles[index];
            try {
                let response = await fetch(item.url);
                if (!response.ok) {
                  // Handle non-successful HTTP response (e.g., 404 Not Found)
                  console.error(`Failed to fetch ${item.original_name}: ${response.status}`);
                  continue; // Skip this file and proceed with the next one
                }
            let blob = await response.blob();
            zip.file(`${index}_${item.original_name}`, blob);
            if (index === selectedFiles.length - 1) {
                const zipData = await zip.generateAsync({
                    type: "blob",
                    streamFiles: true,
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(zipData);
                link.download = name; 
                link.click();
                if(errCount > 0){
                    showToast('ERROR',`${errCount} ${errCount > 1 ? 'files' : 'file'} got failed to download`);
                }
                // Flag false -> default false
                setDownloadFlag(false);
            }
        }catch (error) {
            errCount = errCount+1;
            console.error(`Error while processing ${item.original_name}: ${error.message}`);
            // Continue with the next file even if there is an error
          }
    }
}

const approveFilesHandler=() =>{
    if(designPreviewCounter<=1 && projectFilesData.fileSelectionModel.length <=5){
      let param ={
         file : projectFilesData.fileSelectionModel,
         project_id:id,
         status:1
      }
     Approvelist(param).then((response) => {
         if (response.data.success) {
            let searchData : any = {...projectFilesData.search,"string":inputText}
            getProjectFilesData({
                'projectId': id,
                'limit':pageSize,
                'page':pageNumber,
                'search':searchData
            });
            const emptyArr : any = []
            dispatch(setFileSelectionModel(emptyArr));
         } else {
             showToast('ERROR', 'Something went wrong!')
         }
     })
     setConfirmPopup({
         ...confirmPopup,
         isVisible: !confirmPopup.isVisible,
     })      
 }else if(projectFilesData.fileSelectionModel.length >5){
     showToast('ERROR', 'Maximum Five files can be Approved')
 }else if(designPreviewCounter >1 && projectFilesData.fileSelectionModel.length <=5){
     showToast('ERROR','Please select only one Design Preview File')
 }

 }


// eslint-disable-next-line
const searchFileName : any =  useCallback(debounce((payload:any)=>{
    getProjectFilesData(payload);                                    
  }, 3000),
  // eslint-disable-next-line
   [inputText]);
    return (
        <>
            {projectFilesData.fileData && <div className="projectList-container">
                <div className="outer-box">
                    <div className="top-header">{headerText}</div>
                    {<FileListFilter
                        searchHandler={() => searchHandler()}
                    ></FileListFilter>}
                    {
                        <>
                        <p className="mb-0 filter-label" style={{marginLeft:"16px"}}>Show</p>
                        <div style={{
                            display:"flex",
                            justifyContent:"space-between"
                        }}>
                        <div style={{
                            display: "flex",
                            padding: "0px 16px",
                            height: "35px",
                        }}> 
                                <Select style={{marginLeft:"2px"}}
                                    value={pageSize}
                                    onChange={(event:any) => {
                                        setPageSize(event.target.value);
                                        let searchData : any = {...projectFilesData.search,"string":inputText}
                                        getProjectFilesData({
                                            'projectId': id,
                                            'limit':event.target.value,
                                            'page':pageNumber,
                                            'search':searchData
                                        });
                                    }}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                                <input
                                type="text"
                                style={{marginLeft:"8px"}}
                                className="searchText"
                                placeholder="Search a File"
                                value={inputText}
                                onChange={(event) => {
                                    setInputText(event.target.value.trim());
                                    let searchData : any = {...projectFilesData.search,"string":event.target.value}
                                    searchFileName({'projectId': id,
                                        'limit':pageSize,
                                        'page':pageNumber,search:searchData});
                                }}
                            />
                        </div>
                        <div className="list-button-group">
                            
                            <div 
                            style={{display:'flex',gap:'4px'}}
                            >
                            <ButtonCustom
                                    label={downloadFlag?`${"Downloading..."}`:`${"Download All/Selected"}`}
                                    variant="contained"
                                    buttonColor="success"
                                    styleClass = "btn-layout"
                                    handleClick={downloadAll}
                                    disabled={downloadFlag}
                                ></ButtonCustom>
                                {(projectFilesData.fileData.length > 0) &&
                                   ( <ButtonCustom
                                        label="Approve Files"
                                        variant="contained"
                                        buttonColor="success"
                                        handleClick={() => setConfirmPopup({
                                            ...confirmPopup,
                                            isVisible: !confirmPopup.isVisible,
                                            buttonText: ['Cancel', 'Save'],
                                            title: 'Approve Files',
                                            label: 'Filesapprove'
                                        })}
                                        disabled={enableApprove}
                                    ></ButtonCustom>)}
                                {isActionAllowed(['can-upload-project-files']) && <ButtonCustom
                                    label="Upload"
                                    variant="contained"
                                    buttonColor="success"
                                    styleClass = "btn-layout"
                                    handleClick={() => {
                                        setConfirmPopup({
                                            ...confirmPopup,
                                            isVisible: !confirmPopup.isVisible,
                                            buttonText: ['Back', 'Proceed'],
                                            title: 'File Type',
                                            label: 'upload'
                                        })
                                        setFileDataDrop([]);
                                        setActionType({ value: '', errorMsg: '' })
                                    }
                                    }
                                ></ButtonCustom>
                                }
                                {
                                    (isActionAllowed(['can-delete-project-files']) && projectFilesData.fileData.length > 0) &&
                                    <ButtonCustom
                                        label="Delete Files"
                                        variant="contained"
                                        buttonColor="error"
                                        handleClick={() => setConfirmPopup({
                                            ...confirmPopup,
                                            isVisible: !confirmPopup.isVisible,
                                            buttonText: ['Cancel', 'Delete'],
                                            title: 'alert',
                                            label: 'delete'
                                        })}
                                        disabled={isEmpty(projectFilesData?.selectionModel)}
                                    ></ButtonCustom>
                                }
                            </div>
                        </div>
                        </div>
                        </>
                    }
                    <div style={{ 'margin': '20px' }}>
                        {
                            projectFilesData.fileData.length === 0 ?
                                <div className="no-result">
                                    <p>There are no files yet in this project to view.</p>
                                    <p className="back-projects" onClick={() => history(DASHBOARD.PROJECT_LIST)}>Back to Projects</p>
                                </div>
                                :
                                <div>
                                    <Grid columns={columns}
                                        rows={projectFilesData.fileData}
                                        autoHeight
                                        pageSize={pageSize}
                                        toolbar={false}
                                        paginationMode="server"
                                        rowCount={totalCount}
                                        onPageChange={
                                            (newPage: any) => {
                                            setPageNumber(newPage+1);
                                            let searchData : any = {...projectFilesData.search,"string":inputText}
                                            getProjectFilesData({
                                                'projectId': id,
                                                'limit':pageSize,
                                                'page':newPage+1,
                                                'search':searchData
                                            });
                                        }}
                                        initialState={{ pagination: { paginationModel: { page: 1, pageSize: pageSize },
                                        } }}
                                        densityNum = {'auto'}
                                        pagenumber={pageNumber}
                                    />                                                                              
                                </div>
                        }
                    </div>
                </div>
            </div>
            }
            <Dialog
                show={confirmPopup.isVisible}
                handleClose={() => {
                    setConfirmPopup({ ...confirmPopup, isVisible: !confirmPopup.isVisible })
                }}
                buttonText={confirmPopup.buttonText}
                saveChanges={() => saveButtonHandler()}
                title={confirmPopup.label === 'view'? (rowIndex >=0 ?projectFilesData.fileData[rowIndex].metadata.original_name:'')
                : confirmPopup.title}
                ErrorText={confirmPopup.label === 'upload' ? actionType.errorMsg : ''}
                maxWidth={confirmPopup.label === 'view' ? 'xl' : 'sm'}
                 className = {confirmPopup.label === 'view' ? "projectList-width" : '' }
            >
                {
                    confirmPopup.label === 'upload' ?
                        <>
                            {
                                <div className="fileUpload">
                                    <div>
                                    <p>Please select the file type being uploaded</p>
                                    <FormControl>
                                         <Select
                                                value={actionType.value ? actionType.value : "Select"}
                                                onChange={(event: any) => setActionType({ value: event.target.value, errorMsg: '' })}
                                            >
                                                <MenuItem value="Select" disabled>
                                                Select
                                                </MenuItem>
                                                <MenuItem value="usageData">Usage data</MenuItem>
                                                <MenuItem value="panelboxPhoto">Panel box photo</MenuItem>
                                                <MenuItem value="contractDocuments">Contract documents</MenuItem>
                                                <MenuItem value="siteEvaluation">Site evaluations</MenuItem>
                                                <MenuItem value="shading_report">Shading Report</MenuItem>
                                                <MenuItem value="SGIP">SGIP</MenuItem>
                                                <MenuItem value="utility">Utility Bill</MenuItem>
                                                <MenuItem value="contract_addendum">Contract Addendum</MenuItem>
                                                <MenuItem value="quote">Competitor quote</MenuItem>
                                                <MenuItem value="design">Design</MenuItem>
                                                <MenuItem value="designpreview">Design Preview</MenuItem>
                                                <MenuItem value="installation_photos">Installation Photos</MenuItem>
                                                <MenuItem value="incentives_documents">Incentives Documents</MenuItem>
                                                <MenuItem value="other">Others</MenuItem>
                                            </Select>
                                    </FormControl>
                                    </div>
                                    <div style={{border: '1px solid #dee2e6'}}>
                                    <Dropzone onDrop={onDrop} accept={"image/*"} />
                                    </div>
                                </div>
                            }


                        </>
                        : confirmPopup.label === 'view' ?
                            <>
                                <div className="fileUpload-image">
                                    <div style={{ 'marginRight': '8px' }}>
                                        {
                                            projectFilesData.fileData.length > 1 &&
                                            <img style={{ cursor: 'pointer' }} onClick={() => {
                                                if (rowIndex === 0) setrowIndex(projectFilesData.fileData.length - 1);
                                                else setrowIndex(rowIndex - 1);
                                            }} src={`${CDN_URL}installer-dashboard/image/left-icon-slider.png`} alt="avatar" />

                                        }
                                    </div>

                                    <div className="uploadedImage" >

                                        <div className='display-imagefile'>
                                            {rowIndex >= 0 ?
                                                (projectFilesData.fileData.length > 0 && projectFilesData.fileData[rowIndex].metadata) && <>
                                                    {
                                                        projectFilesData.fileData[rowIndex].metadata?.url.includes('.jpg') || projectFilesData.fileData[rowIndex].metadata?.url.includes('.jpeg') ||
                                                        projectFilesData.fileData[rowIndex].metadata?.url.includes('.JPEG') || projectFilesData.fileData[rowIndex].metadata?.url.includes('.JPG') ||
                                                        projectFilesData.fileData[rowIndex].metadata?.url.includes('.png') ||
                                                        projectFilesData.fileData[rowIndex]?.metadata?.url.includes('.PNG') ?
                                                                <img src={projectFilesData.fileData[rowIndex].metadata.url} alt="avatar" />

                                                            :
                                                            <div className='downloadItems'>
                                                                {
                                                                    projectFilesData.fileData[rowIndex]?.metadata?.url.includes('.pdf') || projectFilesData.fileData[rowIndex]?.metadata?.url.includes('.PDF')?
                                                                    <object data={projectFilesData.fileData[rowIndex].metadata.url} type="application/pdf" width="100%" height="100%">
                                                                    <p>Preview not available</p>
                                                                </object>
                                        
                                                                :
                                                                <>
                                                                    <img style={{ width: '50%',height:'auto' }} src={`${CDN_URL}partner_assets/control-center/preview-not-available.svg`} alt="download icon" />
                                                                <p style={{     'margin': '8px 0 16px 0px' }}>Preview not available</p> 
                                                                </>
                                                                }
                                                                <a href={projectFilesData.fileData[rowIndex].metadata.url} target="_blank" download={projectFilesData.fileData[rowIndex].metadata.original_name} id={`download1_${projectFilesData.fileData[rowIndex].id}`} className="d-none" rel="noreferrer"> file</a>
                                                                <button onClick={() => document.getElementById(`download1_${projectFilesData.fileData[rowIndex].id}`).click()}> Download</button>
                                                            </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </div>

                                    </div>
                                    <div style={{ 'marginLeft': '8px' }}>
                                        {
                                            projectFilesData.fileData.length > 1 &&
                                            <img style={{ cursor: 'pointer' }} onClick={() => {
                                                if (rowIndex === projectFilesData.fileData.length - 1) setrowIndex(0);
                                                else setrowIndex(rowIndex + 1)
                                            }} src={`${CDN_URL}installer-dashboard/image/right-icon-slider.png`} alt="avatar" />

                                        }

                                    </div>

                                </div>
                            </> 
                        : confirmPopup.label==='Filesapprove'?
                        <>
                         {
                                
                            `Are you sure you want to Approve the selected files(s)?`
                         }
                        </> :
                            <>
                                Are you sure you want to delete selected files(s)?
                            </>
                }
            </Dialog>
        </>
    );
}


export default ProjectFilesList;