import { Action, createSlice } from "@reduxjs/toolkit";
import { ENGINE_URL, HUBBLE_ENGINE_URL } from "../CONSTANTS";
import { ThunkAction } from 'redux-thunk';
import axiosService from "../../support/http/AxiosService";
import { showToast } from "../../layout";


export const projectListInitialState = () => ({
  data: {},
  projectDetails: {},
  projectStatus: {},
  projectStage:null,
  fileData:[],
  sdrCallQuestionsList:[],
  sdrEaQuestionsList:[],
  selectionModel:[],
  search: {},
  fileSelectionModel:[],
  installerReassignmentData:{},
  installerHistory:[],
  evseStages:[],
  handleAccordion:{
    summarySection:true,
    detailsSection:false,
    additionalDetailsSections:false
  },
  projectDetailsLoader:false,
  updateProjectDetailsFlag:false,
  sdrQuestionaireLoader:false,
  filesLiestLoader: false,
  installerLoader:false,
  tooltipData:{},
  overViewChangesHandler : false,
  notesSectionHandler: false
});

export const projectListSlice = createSlice({
  name: "projectList",
  initialState: projectListInitialState(),
  reducers: {
    setProjectDetails(state:any, action:any){
      state.projectDetails = action.payload
    },
    setProjectStatus(state:any,action:any){
      state.projectStatus = action.payload
    },
    setUpdateProjectDetailsFlag(state:any,action:any){
      state.updateProjectDetailsFlag = action.payload;
    },
    setFileData(state:any,action:any){
      state.fileData=action.payload
    },
    setSDRCallQuestionsList(state:any,action:any){
      state.sdrCallQuestionsList=action.payload
    },
    setSelectionModel(state:any,action:any){
      state.selectionModel=action.payload
    },
    setFileSelectionModel(state:any,action:any){
      state.fileSelectionModel=action.payload
    },
    setSearch(state:any,action:any){
      state.search = action.payload
    },
    setInstallerReassignmentData(state:any,action:any){
      state.installerReassignmentData = action.payload
    },
    setInstallerHistory(state:any,action:any){
      state.installerHistory = action.payload
    },
    setEvseStages(state:any,action:any){
      state.evseStages = action.payload
    },
    setHandleAccordion(state:any,action:any){
      state.handleAccordion = action.payload
    },
    setSdrEaQuestionsList(state:any,action:any){
      state.sdrEaQuestionsList = action.payload
    },
    setProjectDetailsLoader(state:any,action:any){
      state.projectDetailsLoader = action.payload
    },
    setSdrQuestionaireLoader(state:any,action:any){
      state.sdrQuestionaireLoader = action.payload
    },
    setFilesListLoader(state:any,action:any){
      state.filesLiestLoader = action.payload
    },
    setInstallerLoader(state:any, action:any){
      state.installerLoader = action.payload
    },
    setProjectStage(state:any,action:any){
    state.projectStage = action.payload;
    },
    setTooltipData(state:any,action:any){
      state.tooltipData = action.payload;
    },
    setOverViewChangesHandler(state:any, action:any){
      state.overViewChangesHandler = action.payload;
    },
    setNotesSectionHandler(state:any, action:any){
      state.notesSectionHandler = action.payload;
    }
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  setProjectDetails,
  setProjectStatus,
  setUpdateProjectDetailsFlag,
  setFileData,
  setSDRCallQuestionsList,
  setSelectionModel,
  setSearch,
  setFileSelectionModel,
  setInstallerReassignmentData,
  setInstallerHistory,
  setEvseStages,
  setHandleAccordion,
  setSdrEaQuestionsList,
  setProjectDetailsLoader,
  setSdrQuestionaireLoader,
  setFilesListLoader,
  setInstallerLoader,
  setProjectStage,
  setTooltipData,
  setOverViewChangesHandler,
  setNotesSectionHandler
} = projectListSlice.actions;

export const getProjectList = (page = 1, limit = 15, search: any): ThunkAction<void, any, unknown, Action> => 
   async (dispatch: any, getState: any) =>{
    axiosService('POST', ENGINE_URL + `control-center/projects-list?page=${page}&limit=${limit}`,search).then((response) => {
        return response;
    })
};

export const getProjectFilesList = async (projectID: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/get-project-files?ignoreLoader=true`, true, null, false,
    projectID
  );
};

export const getProjectListConfig = (page = 1, limit = 15, search: any) => {
    return axiosService('POST',ENGINE_URL + `control-center/projects-list?page=${page}&limit=${limit}`, true, null, false, search);
};

export const getprojectListFilters = () => {
    return axiosService('GET' ,ENGINE_URL + `control-center/project-list-filters`, true);
}

export const deleteFilesList = async (data: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/delete-project-files`, true, null, false,
    data
  );
};
export const Approvelist = async (data: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/v2/review-design-file`, true, null, false,
    data
  );
};

export const uploadFileMulti = async (data: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/update-multi-project-file`, true, null, true,
    data
  );
};

export const updateProjectFiles = async (data: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/update-project-file`, true, null, false,
    data
  );
};

export const updateGdriveLink = async (data: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/project-gdrive-sync-status?ignoreLoader=true`,true, null, false,
    data
  );
};

export const getprojectFileListFilters = async (id:any) => {
  return axiosService('GET',
    ENGINE_URL + `control-center/project-files-list-filters/${id}?ignoreLoader=true`,true, null, false,
  );
};

export const getprojectDetails = (id: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  const detailsLoader : any = true;
  dispatch(setProjectDetailsLoader(detailsLoader));
  axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getProjectDetails/${id}?ignoreLoader=true`,true
  ).then((response) => {
    if (response.data.success) {
      const detailsLoader : any = false;
      dispatch(setUpdateProjectDetailsFlag(detailsLoader));
      dispatch(setProjectDetailsLoader(detailsLoader));
      dispatch(setProjectDetails(response.data.data));
      if(response.data.data.partner_project_type === 'evCharger'){
        dispatch(getInstallersList(id,{},{
          'customer-key':response.data.data?.channel_partner?.api_key
        }));
        dispatch(getInstallerHistory(id));
      }
    }
  }).catch((err:any)=>{
    const detailsLoader : any = false;
    dispatch(setProjectDetailsLoader(detailsLoader));
  })
};


export const getProjectStatus = (id: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getProjectStatus/${id}?ignoreLoader=true`,true
  ).then((response) => {
    if (response.data.success) {
      dispatch(setProjectStatus(response.data.data));
    }
  });
};

export const getprojectDetailsNotes = async (data: any) => {
  return axiosService('POST' , HUBBLE_ENGINE_URL + `project/getProjectNotes?ignoreLoader=true`,  true, null, false,
  data);
};

export const updateProjectNotes = async (data: any) => {
  return axiosService('POST',
    HUBBLE_ENGINE_URL + `project/upsertProjectNotes?ignoreLoader=true`, true, null, false,
    data
  );
};

export const getprojectOptionValue = async () => {
  return axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getProjectOptionValues?ignoreLoader=true`, true
  );
};

export const UpdateProjectDetails = async (data: any) => {
  return axiosService('POST',
    HUBBLE_ENGINE_URL + `project/updateProjectDetails`, true, null, false,
    data
  );
};
export const GetProjectSource = async (id: any) => {
  return axiosService('GET' , HUBBLE_ENGINE_URL + `project/getProjectSource/${id}`, true);
};
export const UpdateProjectSource = async (data: any) => {
  return axiosService('POST' , HUBBLE_ENGINE_URL + `project/updateProjectSource`,  true, null, false,data);
};

export const approveFiles = async (data: any) => {
  return axiosService('POST',
    ENGINE_URL + `control-center/file-approve`,true, null, false,
    data
  );
};

//hubble/project/getSDRCallQuestion/
export const getSDRCallQuestionList = (id: any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  const srdLoader : any = true;
  dispatch(setSdrQuestionaireLoader(srdLoader));
  axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getSDRCallQuestion/${id}?ignoreLoader=true`,true
  ).then((response) => {
    if (response.data.success) {
      const srdLoader : any = false;
  dispatch(setSdrQuestionaireLoader(srdLoader));
      dispatch(setSdrEaQuestionsList(response.data.data.slice(0,2)));
      dispatch(setSDRCallQuestionsList(response.data.data.slice(2)));
    }
  }).catch((err:any)=>{
    const srdLoader : any = false;
  dispatch(setSdrQuestionaireLoader(srdLoader));
  })
};

//hubble/project/saveSDRCallQuestion

export const saveSDRCallQuestionList = async (data: any,loader:boolean) => {
  return axiosService('POST',
  HUBBLE_ENGINE_URL + `project/saveSDRCallQuestion${loader ? '?ignoreLoader=true' : ''}`,true, null, false,
  data
);
};

export const getDownloadFileUrls = async (id:any,data: any) => {
  return axiosService('POST',
  ENGINE_URL + `control-center/download-file-urls/${id}?ignoreLoader=true`,true, null, false,
  data
);
};


export const getInstallersList = (id:any,data: any, headers:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  const loader : any = true;
  dispatch(setInstallerLoader(loader))
  axiosService('GET',
  ENGINE_URL + `injection/project/${id}/installerRefresh?ignoreLoader=true`,true, headers, false,
  data
).then((response) => {
    if (response.data.success) {
      const loader : any = false;
      dispatch(setInstallerLoader(loader))
      dispatch(setInstallerReassignmentData(response.data.data));
    }
  }).catch((err:any) => {
    const loader : any = false;
    dispatch(setInstallerLoader(loader))
    showToast('ERROR', err.response.data.message);
  })
};

//injection/project/update/{projectId}/refreshQuote

export const updateInstaller = (id:any,data: any,headers:any): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  const loader : any = true;
  dispatch(setInstallerLoader(loader))
  axiosService('PUT',
  ENGINE_URL + `injection/project/update/${id}/refreshQuote?ignoreLoader=true`,true, headers, false,
  data
).then((response) => {
    if (response.data.success) {
      const loader : any = false;
      dispatch(setInstallerLoader(loader))
      dispatch(getInstallerHistory(id));
      dispatch(setInstallerReassignmentData(response.data.data));
  }
  }).catch((err:any) => {
    const loader : any = false;
    dispatch(setInstallerLoader(loader))
    showToast('ERROR', err.response.data.message);
  })
};

//hubble/getInstallerProjectHistory/{projectId)
export const getInstallerHistory = (id:any,): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('GET',
  ENGINE_URL + `hubble/getInstallerProjectHistory/${id}?ignoreLoader=true`,true, null, false,
).then((response) => {
    if (response.data.success) {
      dispatch(setInstallerHistory(response.data.data));
    }
  });
};

//project/updateEvseElectrumFees
export const updateEvseElectrumFees = async (data: any) => {
  return axiosService('POST',
  HUBBLE_ENGINE_URL + `project/updateEvseElectrumFees`,true, null, false,
  data
);
};

//getInstallerAssignmentStatus

export const getInstallerAssignmentStatus = async (id: any) => {
  return axiosService('GET',
  HUBBLE_ENGINE_URL + `getInstallerAssignmentStatus/${id}?ignoreLoader=true`,true, null, false,
);
}

//hubble/project/getElectrumFeeUpdateStages
export const getElectrumFeeUpdateStages = (): ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any) => {
  axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getElectrumFeeUpdateStages?ignoreLoader=true`,true
  ).then((response) => {
    if (response.data.success) {
      const stages : any= [];
      response.data.data?.forEach(obj => {
        stages.push(obj.installer_display_name);
    });
      dispatch(setEvseStages(stages));
    }
  });
};


export const getLinkedProjects = async (data: any) => {
  return axiosService('POST',
    HUBBLE_ENGINE_URL + `project/getLinkedProjects?ignoreLoader=true`,true, null, false,
    data
  );
};

// project/getProjectConfigurations

export const getProjectConfigurations = async (id: any) => {
  return axiosService('GET',
  HUBBLE_ENGINE_URL + `project/getProjectConfigurations/${id}?ignoreLoader=true`,true, null, false,
);
}

// project/updateProjectConfigurations
export const updateProjectConfigurations = async (data: any) => {
  return axiosService('POST',
    HUBBLE_ENGINE_URL + `project/updateProjectConfigurations?ignoreLoader=true`,true, null, false,
    data
  );
};

//getToolTips

export const getTooltips = (): ThunkAction<void, any, unknown, Action> => 
  async (dispatch: any, getState: any) => {
    axiosService('GET',
      HUBBLE_ENGINE_URL + `project/getToolTips?ignoreLoader=true`,true
    ).then((response) => {
      if (response.data.success) {
        dispatch(setTooltipData(response.data.data[0]));
      }
    });
  };

export default projectListSlice.reducer;