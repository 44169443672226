import { createSlice,Action } from "@reduxjs/toolkit";
import {  HUBBLE_ENGINE_URL } from "../CONSTANTS";
import axiosService from "../../support/http/AxiosService";
import { ThunkAction } from 'redux-thunk';
import { showToast } from "../../layout";



export const contractDetailInitialState = () => ({
    contractUserdata: undefined,
    contarctMain:[],
    contractInputsFiled:{
      customerEmail:"",
      InstallerEmail:""
    },
    CarbonCopies:[],
    EnvelopeHistory:[],
    EnvelopeId:"",
    templateInfo:[],
    envelopFiles:[],
    Envelopedisabled:false
  });
  export const contractDetailSlice = createSlice({
    name: "contract",
    initialState: contractDetailInitialState(),
    reducers: {
      setcontractDetailsLength(state: any, action: any) {
        state.contractUserdata = action.payload
      },
      setcontarctMain(state: any, action: any){
        state.contarctMain = action.payload
      },
      setcontractInputs(state: any, action: any){
        state.contractInputsFiled = action.payload
      },
      setCarboncopies(state: any, action: any){
        state.CarbonCopies = action.payload
      },
      setEnvelopeHistory(state: any, action: any){
        state.EnvelopeHistory = action.payload
      },
      setEnvelopeID(state: any, action: any){
        state.EnvelopeId = action.payload
      },
      setTemplateInfo(state: any, action: any){
        state.templateInfo = action.payload
      },
      setEnvelopeDisabled(state:any,action:any){
        state.Envelopedisabled = action.payload
      },
      setEnvelopeFiles(state:any,action:any){
        state.envelopFiles = action.payload
      }
    },
  });

export const { setcontractDetailsLength,setcontarctMain,setcontractInputs,setCarboncopies,setEnvelopeHistory,setEnvelopeID,setTemplateInfo,setEnvelopeDisabled, setEnvelopeFiles } = contractDetailSlice.actions;


  export const getProjectEnvelope =  (id:any):ThunkAction<void, any, unknown, Action> => 
  async (dispatch: any, getState: any ,) => {
     axiosService('GET',
      HUBBLE_ENGINE_URL + `project/docusign/get-docusign-project-envelope/${id}`, true, null, false,
    ).then((response) => {
      if (response.data.success) {
        const envelopData = response?.data?.data;
        dispatch(setcontractDetailsLength(envelopData))
        dispatch(setcontarctMain(envelopData?.json_request?.documents))
        dispatch(setcontractInputs({
          ...getState.contractInputsFiled,
          customerEmail:envelopData?.json_request?.recipients?.signers[0]?.email,
          InstallerEmail:envelopData?.json_request?.recipients?.signers[1]?.email
        }))
        dispatch(setCarboncopies(envelopData?.json_request?.recipients?.carbonCopies))
        dispatch(setEnvelopeID(envelopData?.envelope_id))
        dispatch(setTemplateInfo(envelopData?.envelope_project_mapping_details))
        if(envelopData?.envelope_status === 'created'){
          const envelopeDisable : any = false;
          dispatch(setEnvelopeDisabled(envelopeDisable));
        }
      }
  });
  };
  export const Updateenvelope = async (data: any) => {
    return axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/update-envelope-cc`, true, null, false , data)
  }
  export const UpdateDateExpiry = async (data: any) => {
    return axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/update-envelope-expiry`, true, null, false , data)
  }
  export const VoidEnvelope = async (data: any) => {
    return axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/void-envelope`, true, null, false , data)
  }
  export const SaveEnvelope = async (data: any) => {
    return axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/save-envelope`, true, null, false , data)
  }
  export const EnvelopeHistory = async (id: any) => {
    return axiosService('GET' , HUBBLE_ENGINE_URL + `project/docusign/get-project-envelopes-history/${id}`, true )
  }
  export const CompatableTemplates = async (id: any,data:any) => {
    return axiosService('GET' , HUBBLE_ENGINE_URL + `project/docusign/get-compatible-templates/${id}`, true, null, false,data )
  }
  export const addTemplate = async (id:any,data: any) => {
    return axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/add-template-to-envelope/${id}`, true, null, false , data)
  }
  export const getsoldprojectDetails= async (id:any)=>{
    return axiosService('GET',HUBBLE_ENGINE_URL + `project/soldProjectDetails/${id}`, true)
  }

  //hubble/project/docusign/remove-template/2887

  export const deleteAdditionalTemplate = (id:any,data: any, project_id?:any) : ThunkAction<void, any, unknown, Action> => async (dispatch : any, getState : any) => {
    axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/remove-template/${id}`, true, null, false , data).then((response)=>{
      if(response.data.success){
        dispatch(getProjectEnvelope(project_id));
      }
    })
  }
//{{url}}/hubble/project/docusign/get-envelope-files

export const envelopePreview = (data: any) => {
  return axiosService('POST' , HUBBLE_ENGINE_URL + `project/docusign/get-envelope-files`, true, null, false , data);
}


  export default contractDetailSlice.reducer;