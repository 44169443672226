import React from "react";
import { TextField, Stack, styled } from "@mui/material";

// Define the styled TextField outside the function component
const CustomTextField = styled(TextField)(({ theme, error, disabled }) => ({
  "& .MuiOutlinedInput-root": {
    padding: 0,
    height: "48px",
    cursor: disabled ? "not-allowed" : "pointer",
    backgroundColor: disabled ? "#f5f5f5" : "white",
    "& fieldset": {
      borderColor: error ? "red" : "#aaa",
    },
    "&:hover fieldset": {
      borderColor: error ? "red" : "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: error ? "red" : "#aaa",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 14px",
    height: "100%",
    boxSizing: "border-box",
    cursor: disabled && "not-allowed",
  },
  "& .MuiFormLabel-root": {
    fontFamily: "ActualR",
    fontWeight: 400,
    fontSize: "14px",
    color: "#AAAAAA",
    paddingTop: '4px',
  },

  "& .MuiFormLabel-root.Mui-focused": {
    color: "#AAAAAA",
  },
  "& .MuiFormLabel-root.MuiInputLabel-shrink": {
    transform: "translate(14px, -12px) scale(0.85)",
    color: "#333333",
  },
  "& .MuiFormHelperText-root": {
    color: error ? "red" : "", // Color helper text red if helperText is provided
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
}));

export const  InputTextField = (props: any) => {
  const { name, label, value, changeHandler, ...rest } = props;
  return (
    <Stack spacing={1}>
      <CustomTextField
        variant="outlined"
        label={rest?.is_required ? (
            <p>
              {label}
              <span style={{ color: "red" }}>*</span>
            </p>
          ) : (
            label
          )}
        autoComplete="off"
        size="small"
        name={name}
        key={rest?.key ?? ""}
        value={value}
        error={rest?.error ? true : false}
        onChange={(e) => {
          changeHandler(e.target.name, e.target.value);
        }}
        helperText={rest?.helperText ? rest?.helperText : ""}
        placeholder={
          rest?.placeHolder && rest?.placeHolder !== "" ? rest?.placeHolder : ""
        }
        {...rest}
      />
    </Stack>
  );
};
