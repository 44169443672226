// Authenticate
export const SET_USER = 'set_user'
export const REMOVE_USER = 'remove_user'
export const GET_USER_LIST = "get_user_list"
export const SET_MODULE_PERMISSIONS = "set_module_permissions"


export const ENGINE_URL = process.env.REACT_APP_BASE_URL;
export const HUBBLE_ENGINE_URL = process.env.REACT_APP_HUBBLE_ENGINE_URL
export const LEAD_URL = process.env.REACT_APP_LEAD_URL;
export const CONTROL_LEAD_URL = process.env.REACT_APP_CONTROL_LEAD_URL;
export const DOWNTIME_URL = process.env.REACT_APP_DOWNTIME_URL;
export const CDN_URL = "https://assets.solar.com";
export const QUICKSIGHT_URL = process.env.REACT_APP_QUICKSIGHT_INSTALLER;
export const SCHEDULER_DEAL_PIPEDRIVE_URL = process.env.REACT_APP_SCHEDULER_DEAL_PIPEDRIVE;
export const SCHEDULER_ACTIVITY_PIPEDRIVE_URL = process.env.REACT_APP_SCHEDULER_ACTIVITY_PIPEDRIVE;
export const INSTALLER_IMPERSONATE_URL = process.env.REACT_APP_INSTALLER_IMPERSONATE;
export const LEGACY_CONTROL_CENTER = process.env.REACT_APP_LEGACY_CONTROL_CENTER;
export const AWS_URL = process.env.REACT_APP_AWS;
export const SSO_ORIGIN = process.env.REACT_APP_SSO_ORIGIN
export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID
export const CONTROL_CENTER = process.env.REACT_APP_CONTROL_CENTER
export const AUTOLOGIN = process.env.REACT_APP_AUTOLOGIN
export const IDENTIFIER = process.env.REACT_APP_ENV_IDENTIFIER
export const HUBBLE_URL = process.env.REACT_APP_HUBBLE_URL
