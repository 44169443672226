import {  Action, createSlice } from "@reduxjs/toolkit";
import axiosService from "../../support/http/AxiosService";
import {  HUBBLE_ENGINE_URL, ENGINE_URL } from "../CONSTANTS";
import { ThunkAction } from 'redux-thunk';
import { showToast } from "../../layout";

type sliceInterface = {
  postSaleManagers : any,
  postSaleBids : any,
  postSaleData : any,
  updatePostSaleData : any,
  stagesData : any,
  postSaleMedia : any
}

export const postSaleInitialState = (): sliceInterface => ({
  postSaleManagers : {},
  postSaleBids : {},
  postSaleData : {},
  updatePostSaleData : {
    "project_id": null,
    "csr_id": null,
    "loan_status": "pending",
    "loan_expiry_date": null,
    "loan_funded_date": null,
    "installer_crm_deal": null,
    "csm_id": null,
    "loan_specialist_id": null,
    "loan_app_approval_date": null,
    "loan_app_submitted_date": null
},
stagesData:{},
postSaleMedia:[]
});

export const postSaleSlice = createSlice({
  name: "postSale",
  initialState: postSaleInitialState(),
  reducers: {
    setPostSaleManagers(state:any, action:any){
      state.postSaleManagers = action.payload
    },
    setPostSaleBids(state:any, action:any){
      state.postSaleBids = action.payload
    },
    setPostSaleData(state:any, action:any){
      state.postSaleData = action.payload
    },
    setUpdatePostSaleData(state:any, action:any){
      state.updatePostSaleData = action.payload
    },
    setStagesData(state:any, action:any){
      state.stagesData = action.payload
    },
    setPostSaleMedia(state:any,action:any){
      state.postSaleMedia = action.payload
    }
  },
});
// eslint-disable-next-line no-empty-pattern
export const {setPostSaleManagers, setPostSaleBids, setPostSaleData, setUpdatePostSaleData,setStagesData,setPostSaleMedia} = postSaleSlice.actions;

//project/getPostSaleManagers

export const getPostSaleManagers = ():ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any ,) => {
   axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getPostSaleManagers`, true, null, false,
  ).then((response) => {
    if (response.data.success) {
        dispatch(setPostSaleManagers(response.data.data));
      }
    }
  )
}

//project/getPostSaleBids

export const getPostSaleBids = (id:any):ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any ,) => {
   axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getPostSaleBids/${id}`, true, null, false,
  ).then((response) => {
    if (response.data.success) {
        dispatch(setPostSaleBids(response.data.data));
      }
    }
  )
}

//project/getPostSaleData

export const getPostSaleData = (id:any):ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any ,) => {
   axiosService('GET',
    HUBBLE_ENGINE_URL + `project/getPostSaleData/${id}`, true, null, false,
  ).then((response) => {
    if (response.data.success) {
        dispatch(setPostSaleData(response.data.data));
        dispatch(setStagesData(response.data.data.stages));
      }
    }
  )
}

//project/updatePostSaleData

export const updatePostSaleData = (data : any):ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any ,) => {
   axiosService('POST',
    HUBBLE_ENGINE_URL + `project/updatePostSaleData`, true, null, false,data
  ).then((response) => {
    if (response.data.success) {
      dispatch(setPostSaleData(response.data.data));
      showToast('SUCCESS','Post Sale data updated Successfully');
      }
    }
  ).catch((err:any)=>showToast('ERROR',err.response.data.message));
}


//project/updatePostSaleStages
export const updatePostSaleStages = (data : any):ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any ,) => {
   axiosService('POST',
    HUBBLE_ENGINE_URL + `project/updatePostSaleStages`, true, null, false,data
  ).then((response) => {
    if (response.data.success) {
      dispatch(setPostSaleData(response.data.data));
      showToast('SUCCESS','Stages Data Updated Successfully');
      }
    }
  ).catch((err:any)=>showToast('ERROR',err.response.data.message));
}

//control-center/update-multi-project-file
export const uploadFiles = async (data: any) => {
  return axiosService('POST' , ENGINE_URL + `control-center/update-multi-project-file`, true, null, true,data)
}

//project/getPostSaleMedia
export const getPostSaleMedia = (data : any):ThunkAction<void, any, unknown, Action> => 
async (dispatch: any, getState: any ,) => {
   axiosService('POST',
    HUBBLE_ENGINE_URL + `project/getPostSaleMedia`, true, null, false,data
  ).then((response) => {
    if (response.data.success) {
      dispatch(setPostSaleMedia(response.data.data));
      }
    }
  ).catch((err:any)=>showToast('ERROR',err.response.data.message));
}

export default postSaleSlice.reducer;