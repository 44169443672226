import {
    combineReducers,
    configureStore,
  } from "@reduxjs/toolkit";
import authenticationReducer, { authenticationInitialState } from "./auth/authSlice";
import configurationReducer, { ConfigurationInitialState } from "./config/configSlice";
import consumptionReducer, { consumptionInitialState } from "./consumption/consumptionSlice";
import systemDesignReducer, { systemDesignInitialState } from "./systemDesign/systemDesignSlice";
import solutionsReducer, { SolutionsInitialState } from "./solutions/solutionsSlice";
import contractReducer,{contractDetailInitialState} from"./contracts/contractSlice";
import projectListReducer, {projectListInitialState} from "./projectList/projectListSlice";
import postSaleReducer, {postSaleInitialState} from "./postSale/postSaleSlice";

import customerQuestionnaireReducer, {customerQuestionnaireInitialState} from "./evse/customerQuestionnaireSlice";
import quotesReducer, {quotesInitialState} from "./evse/quotesSlice";


  export const INITIAL_STATE = {
    auth: authenticationInitialState(),
    config: ConfigurationInitialState(),
    consumption: consumptionInitialState(),
    systemDesign: systemDesignInitialState(),
    solutions: SolutionsInitialState(),
    contracts:contractDetailInitialState(),
    projectList: projectListInitialState(),
    postSale: postSaleInitialState(),
    customerQuestionnaire: customerQuestionnaireInitialState(),
    quotes: quotesInitialState(),

  }

  const combinedReducer = combineReducers({
    auth: authenticationReducer,
    config: configurationReducer,
    consumption: consumptionReducer,
    systemDesign: systemDesignReducer,
    solutions: solutionsReducer,
    contracts:contractReducer,
    projectList: projectListReducer,
    postSale: postSaleReducer,
    customerQuestionnaire: customerQuestionnaireReducer,
    quotes: quotesReducer

  })

  const rootReducer = (state: any, action: any) => {
    if (action.type === "auth/logoutAction" || action.type === "RESET") {
      state = INITIAL_STATE;
    }
    return combinedReducer(state, action);
  };
  
  export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>[
      ...getDefaultMiddleware({
        serializableCheck: true,
      }),
    ],
    preloadedState: INITIAL_STATE,
  });
